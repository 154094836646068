<template>
  <CChartLine v-if="tank"
    :datasets="datasets"
    :options="options"
    :labels="labels"
  />
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils/src'
import {unitSign, LTR2GL, tempC2F, sleep} from "@/common/utils";

function transVol(to,val){
  if (to==='g') return Math.round(val * LTR2GL )
  else return val;
}


export default {
  name: 'TankLineChart',
  components: {
    CChartLine
  },
  props:{
    tank: { type: Object, default: null },
    oilColor: { type: String, default: null },
  },
  data () {
    return {
      datasets: [],
      options: {},
      labels: [],
    }
  },
  computed: {
  },

  created() {
    // console.log('[TankLineChart]-------Created--',this.tank.name);
    this.setOptions();
  },

  methods: {
    async setChartData(data){
      // console.log('[TankLineChart]------ count=', data?.ovms?.length );


      const tank = this.tank;
      const {unitOfTmp} = this.tank;
      const {unitOfVol} = this.tank;

      this.setOptions();
      await sleep(200);

      if(!data?.ovms.length) {
        this.datasets = [];
        return
      }

      let ovms = [], ovcs = [], tmps = [];  // gross vol, net vol, 온도

      if(unitOfVol==='g'){
        ovms = data.ovms.map(val=>{ return Math.round(val * LTR2GL)})
        ovcs = data.ovcs.map(val=>{ return Math.round(val * LTR2GL)})
      }else{
        ovms = data.ovms;
        ovcs = data.ovcs;
      }

      if( unitOfTmp==='f'){
        tmps = data.tmps.map(val=> {return tempC2F(val)} );
        tmps = data.tmps.map(val=> {return Math.round(val * (9 / 5) + 32)} );
      }else{
        tmps = data.tmps;
      }

      this.datasets = [
        {
          label: `Gross (${unitOfVol==='g'?'GL':'ltr'})`,
          backgroundColor: hexToRgba( tank.color, 20),
          borderColor: this.oilColor,
          pointHoverBackgroundColor: this.oilColor,
          borderWidth: 3,
          data: ovms,
          yAxisID: 'volume'
        },
        {
          label: `Net (${unitOfVol==='g'?'GL':'ltr'})`,
          backgroundColor: 'transparent',
          borderColor: getStyle('success') || '#4dbd74',
          pointHoverBackgroundColor: tank.color,
          borderWidth: 1,
          data: ovcs,
          yAxisID: 'volume'
        },

        {
          label: '온도',
          backgroundColor: 'transparent',
          borderColor: getStyle('danger') || '#f86c6b',
          pointHoverBackgroundColor: getStyle('danger') || '#f86c6b',
          borderWidth: 1,
          borderDash: [8, 5],
          data: tmps,
          yAxisID: 'temp'
        }
      ];

      this.labels = data.labels;

    },

    setOptions(){
      const tank = this.tank;

      // console.log('----------TankLineChart------- tankVolume--->', transVol('g',tank.tankVolume));
      // console.log( "---------TankLineChart------- tankData Object --->", data );
      // console.log( "---------TankLineChart------- oilColor --->", this.oilColor );


      const {unitOfTmp} = this.tank;
      const {unitOfVol} = this.tank;


      this.options = {
        title: {
          display: false,
          fontStyle: "bold",
          fullWidth: false,
          padding: 1,
          position: "top",
          text: `${tank.tankCode} ${tank.name}`,
          weight: 1000
        },
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "top",
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [
            {
              id: 'volume',
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 10,
                stepSize: Math.round(transVol(unitOfVol,tank.tankVolume) / (unitOfVol==='g')? 200:1000 ),
                max: Math.round( transVol(unitOfVol, tank.tankVolume) ) ,
              },
              gridLines: {
                display: true
              },

            },
            {
              id: 'temp',
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 10,
                stepSize: 0.5,
                max: unitOfTmp==='f'? 123:50,
                min: unitOfTmp==='f'? 14:-10
              },
              position: 'right',
              gridLines: {
                display: true
              },
              scaleLabel: {
                labelString: `온도 (${unitSign(unitOfTmp)})`,
                display: true
              }

            }
          ]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      };
    }

  }
}
</script>
